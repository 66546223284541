<ng-container *transloco="let t">
  @if (imageUrl && !isPlaying) {
    <img class="placeholder-image" [src]="imageUrl" [alt]="altText" />
  }
  @if (!isPlaying) {
    <button
      type="button"
      (click)="play()"
      (keyup.space)="!isPlaying && play($event)"
      class="video-play-button"
      [attr.aria-label]="t('ui.Play video title', { title })">
      <svg-icon class="icon" [ngClass]="{ primary: primary }" src="/assets/icons/play-circle.svg"></svg-icon>
    </button>
  }
  <video
    [ngClass]="{ 'not-loaded': !isPlaying }"
    controls
    #player
    preload="auto"
    (timeupdate)="updateTime()"
    (ended)="ended()"
    (play)="playStarted()"
    (pause)="paused()">
    <source [src]="url" />
    {{ t('ui.Sorry, your browser doesnt support embedded videos.') }}
  </video>
</ng-container>
