<ng-container *transloco="let t">
  <div class="outer-container">
    @if (showControls) {
      <div class="container">
        <div class="controls-container">
          <span class="buttons-container">
            <button
              type="button"
              class="audio-button"
              [attr.aria-label]="t('ui.Play audio title', { title })"
              (click)="play()"
              (keyup.space)="!isPlaying && play($event)"
              [ngClass]="{ show: !isPlaying }"
              [hidden]="isPlaying"
              [attr.aria-hidden]="isPlaying"
              [tabindex]="isPlaying ? -1 : 0"
              #audioPlayButton>
              <svg-icon class="icon" src="/assets/icons/play-circle.svg"></svg-icon>
            </button>
            <button
              type="button"
              class="audio-button"
              [attr.aria-label]="t('ui.Pause audio')"
              (click)="pause()"
              (keyup.escape)="isPlaying && pause($event)"
              (keyup.space)="isPlaying && pause($event)"
              [ngClass]="{ show: isPlaying }"
              [hidden]="!isPlaying"
              [attr.aria-hidden]="!isPlaying"
              [tabindex]="!isPlaying ? -1 : 0"
              #audioPauseButton>
              <svg-icon class="icon" src="/assets/icons/pause-circle.svg"></svg-icon>
            </button>
          </span>
          @if (isPlaying) {
            <span>{{ currentTime }}</span>
          }
          @if (!isPlaying) {
            <span>{{ t('ui.Listen') }}</span>
          }
          <span>&nbsp;|&nbsp;{{ durationDisplay }}</span>
        </div>
        <progress
          #progress
          value="{{ current }}"
          max="{{ duration }}"
          (click)="seek($event)"
          (keyup.escape)="isPlaying && pause($event)"
          (keydown.enter)="isPlaying ? pause($event) : play($event)"
          (keyup.space)="isPlaying ? pause($event) : play($event)"
          (keyup.arrowLeft)="seek($event, -0.01)"
          (keyup.shift.arrowLeft)="seek($event, -0.1)"
          (keyup.arrowRight)="seek($event, 0.01)"
          (keyup.shift.arrowRight)="seek($event, 0.1)"
          tabindex="0"
          [attr.aria-label]="t('ui.Playing audio')"></progress>
      </div>
    }

    <div class="audio-container">
      <audio
        controls
        #player
        preload="auto"
        (timeupdate)="updateTime()"
        (ended)="ended()"
        (loadedmetadata)="getDuration()"
        (play)="playStarted()"
        (pause)="paused()"
        (error)="onError()"
        hidden
        aria-hidden="true"
        tabindex="-1">
        <source src="{{ url }}" type="audio/mpeg" />
        {{ t('ui.Your browser does not support the audio element.') }}
      </audio>
    </div>
  </div>
</ng-container>
