import { Component } from '@angular/core';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'kauth-auth-sso-logout',
  standalone: true,
  templateUrl: './auth-sso-logout.component.html',
  styleUrls: ['./auth-sso-logout.component.scss'],
  imports: [TranslocoDirective],
  providers: [provideTranslocoScope('auth')],
})
export class AuthSsoLogoutComponent {}
