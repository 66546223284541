<ng-container *transloco="let t">
  <div
    class="overlay hidden-print"
    [ngClass]="overlayClasses"
    (click)="closeModal(actionChoice.clickOutsideModal)"
    (keyup.escape)="closeModal(actionChoice.clickOutsideModal)"
    tabindex="0"></div>

  @if (active) {
    <div
      class="modal-top-container"
      [ngClass]="modalClasses"
      role="dialog"
      [attr.aria-describedby]="describedById"
      [attr.aria-label]="labelValue">
      @if (canDismiss && showX) {
        <button
          [ngClass]="{ reverse: reverseClose }"
          class="close hidden-print"
          (click)="closeModal(actionChoice.clickX)"
          [attr.aria-label]="t('ui.Close Modal')">
          &times;
        </button>
      }
      @if (html) {
        <div class="content" [innerHTML]="parsedHtml + parsedHtmlStyles.join('') | sanitizeHtml"></div>
      }
      @if (!html) {
        <ng-content></ng-content>
      }
    </div>
  }
</ng-container>
