import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { AnalyticEvent, AnalyticsBloc, AnalyticsData } from '@kanalytics';
import { FirstVisitBloc } from '@kbloc';
import { SharedConstants, WINDOW } from '@kservice';
import { FirstVisitCase } from '@ktypes/enums';
import { JsonObject } from '@ktypes/models';
import { StringUtil, extractStyleTags, removeStyleTags } from '@kutil';
import { MockComponent } from '@kutil/test';
import { take } from 'rxjs/operators';
import { ModalKey, UiModalCoordinatorService } from './ui-modal-coordinator.service';

@Component({
  selector: 'kui-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    @Inject(DOCUMENT) private _document: Document,
    private _firstVisit: FirstVisitBloc,
    private _modalElement: ElementRef,
    private _renderer: Renderer2,
    private _translocoService: TranslocoService,
    private _uiModalCoordinatorService: UiModalCoordinatorService,
    @Inject(WINDOW) private _window: Window
  ) {}

  // analytics data plus open & close events as tuple
  @Input() analyticsData: [AnalyticsData, AnalyticEvent, AnalyticEvent];
  @Input() canDismiss = true;
  @Input() closeFocus: HTMLElement;
  @Input() describedById = 'describedById';
  /** HTML content to show. */
  @Input() html: string = null;
  // Two-way data binding. Use `[(isOpen)]="some component property"` to open/close
  // this modal based off of an internal variable in the parent host component.
  @Input() isOpen = false;
  @Input() labelValue = 'ui.Modal'; // NOTE: Translate before passing in
  @Input() modalKey: ModalKey;
  @Input() reverseClose = false;
  @Input() showX = true;
  /**
   * Available modal types: (TODO: use enum?)
   * - detail (default)
   * - info
   * - legal
   * - popover
   * - form
   * - photo
   */
  @Input() type = 'detail';

  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() modalCloseMethod = new EventEmitter<string>();

  modalClasses: JsonObject = {};
  overlayClasses: JsonObject = {};
  parsedHtml: string;
  parsedHtmlStyles: string[] = [];
  actionChoice = SharedConstants.actionChoice;

  private _focusableElements: HTMLElement[];
  private _blurListener: () => void;
  private _scrollY: number | string;

  // Because we are running transitions, we want to turn these
  // classes off and on at different times.
  @HostBinding('class.visible') visible = false;
  @HostBinding('class.active') active = false;

  @HostBinding('class.content-flex')
  @Input()
  contentFlex = false;

  @HostListener('window:keydown.escape', ['$event']) triggerEsc(event: KeyboardEvent) {
    this.closeModal(this.actionChoice.clickOutsideModal);
  }

  ngOnInit() {
    if (this.labelValue === 'ui.Modal') {
      /**
       * t(ui.Modal)
       */
      this._translocoService
        .selectTranslate(this.labelValue, { scope: 'ui', alias: 'ui' })
        .pipe(take(1))
        .subscribe((translatedModal: string) => {
          this.labelValue = translatedModal !== 'ui.Modal' ? translatedModal : 'Modal';
        });
    }
    // Directly update DOM to preventing scrolling
    this._scrollY = this._window.scrollY;
    this._document.documentElement.style.top = `-${this._scrollY}px`;
    this._renderer.addClass(this._document.documentElement, 'no-scroll');
    this.modalClasses = {
      'content-container': ['legal', 'detail'].includes(this.type),
      'full-detail-container': this.type === 'detail',
      'info-container': ['info', 'photo'].includes(this.type),
      'form-container': this.type === 'form',
      'app-switcher-popover': this.type === 'popover',
      'photo-container': this.type === 'photo',
    };
    // didn't end up using overlayClasses, but leaving here for future use - delete this comment on first use - MB
    this.overlayClasses = {};
    if (this.html) {
      const htmlContentFull = StringUtil.contentAsParsedHtml(this.html);
      this.parsedHtml = removeStyleTags(htmlContentFull);
      this.parsedHtmlStyles = extractStyleTags(htmlContentFull);
    }
    this._firstVisit.updateFirstVisit(FirstVisitCase.lastModalLaunch, new Date().toISOString());
  }

  ngAfterViewInit() {
    this._setupFocusRotation();
  }

  ngOnDestroy() {
    // Cleanup DOM changes for preventing scrolling; restore scroll position
    this._renderer.removeClass(this._document.documentElement, 'no-scroll');
    this._scrollY = this._document.documentElement.style.top;
    this._document.documentElement.style.top = '';
    this._window.scrollTo(0, parseInt(this._scrollY || '0', 10) * -1);

    if (this._focusableElements && this._focusableElements.length > 0) {
      // remove listener
      this._blurListener();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpen) {
      if (changes.isOpen.currentValue) {
        this.visible = true;
        this.active = true;
        if (this.analyticsData && this.analyticsData[1] != null) {
          this._analyticsBloc.sendEvent(
            this._analyticsBloc.createEventFromInteraction({
              page: this.analyticsData[0].page,
              event: this.analyticsData[1],
              meta: this.analyticsData[0].meta,
            })
          );
        }
      } else {
        this.isOpen = false;
        this.visible = false;
        setTimeout(() => {
          this.active = false;
        }, 400); // value must match the transition duration in modal.component.scss
      }
    }
  }

  updateFocusRotation() {
    this._setupFocusRotation();
  }

  private _setupFocusRotation() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    this._focusableElements = this._modalElement.nativeElement.querySelectorAll(
      'input, select, textarea, a[href], button, [tabindex]:not([tabindex="-1"]), audio[controls], video[controls], [contenteditable]:not([contenteditable="false"])'
    ) as HTMLElement[];
    const numFocusableElements = this._focusableElements && this._focusableElements.length;
    if (numFocusableElements > 0) {
      this._focusableElements[0].focus();
      this._blurListener = this._renderer.listen(this._focusableElements[numFocusableElements - 1], 'blur', () => {
        if (this._focusableElements && this._focusableElements.length > 0) {
          // on blur of the last focusable element, return to the first one
          this._focusableElements[0].focus();
        }
      });
    }
  }

  closeModal(actionChoice: string) {
    if (this.closeFocus && typeof this.closeFocus.focus === 'function') {
      this.closeFocus.focus();
    }
    this._uiModalCoordinatorService.clearModal();

    if (this.analyticsData && this.analyticsData[2] != null) {
      this._analyticsBloc.sendEvent(
        this._analyticsBloc.createEventFromInteraction({
          page: this.analyticsData[0].page,
          event: this.analyticsData[2],
          meta: this.analyticsData[0].meta,
        })
      );
    }

    // Tell the parent host component to change their internal variable that
    // binds to `this.isOpen`, which will also cause `this.isOpen` to be set to
    // `false`.
    if (this.canDismiss) {
      this.isOpenChange.emit(false);
      this.modalCloseMethod.emit(actionChoice);
    }
  }
}

export const MockModalComponent = MockComponent({
  selector: 'kui-modal',
  inputs: [
    'analyticsData',
    'canDismiss',
    'closeFocus',
    'describedById',
    'html',
    'isOpen',
    'labelValue',
    'modalKey',
    'reverseClose',
    'type',
  ],
  template: '<ng-content></ng-content>',
});
