import { Component, Input } from '@angular/core';
import { TranslocoDirective, provideTranslocoScope } from '@jsverse/transloco';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-ai-coach-label',
  standalone: true,
  templateUrl: './ai-coach-label.component.html',
  styleUrls: ['./ai-coach-label.component.scss'],
  imports: [TranslocoDirective],
  providers: [provideTranslocoScope('ui')],
})
export class AiCoachLabelComponent {
  // eventually probably set this via UserTag (Feature Flag), but defaults to true for now
  @Input() isBeta = true;
}

export const MockAiCoachLabelComponent = MockComponent({
  selector: 'kui-ai-coach-label',
  inputs: ['isBeta'],
});
