import { Component, input } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-email-screens-base',
  standalone: true,
  templateUrl: './email-screens-base.component.html',
  styleUrls: ['./email-screens-base.component.scss'],
})
export class EmailScreensBaseComponent {
  headerTitle = input<string>(); // NOTE: Translate before passing in
}

export const MockEmailScreensBaseComponent = MockComponent({
  selector: 'kui-email-screens-base',
  standalone: true,
  inputs: ['headerTitle'],
  template: '<ng-content></ng-content>',
});
