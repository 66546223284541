import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, Output } from '@angular/core';
import { SharingBloc } from '@kbloc';
import { DataStoreService, SharedConstants } from '@kservice';
import { Invite, InviteType } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-modal-invite',
  templateUrl: './modal-invite.component.html',
  styleUrls: ['./modal-invite.component.scss'],
})
export class ModalInviteComponent implements OnChanges {
  @Input() invite: Invite;
  @Input() challengeId?: string;
  @Input() challengeInviteMessage?: string; // NOTE: Translate before passing in
  @Input() challengeTitle?: string;

  @Output() closeClicked = new EventEmitter<void>();

  constants = SharedConstants;
  inviteType = InviteType;
  proceedToLink = false;
  showTooltip = false;

  constructor(
    public dataStoreService: DataStoreService,
    @Inject(DOCUMENT) private _document: Document,
    public sharingBloc: SharingBloc
  ) {}

  ngOnChanges() {
    this.sharingBloc.getShortLink(this.invite.sharingUrl);
    if (this.challengeId) {
      this.sharingBloc.getChallengeSharingLink(this.challengeId);
    }
  }

  copyLink(inputElement: HTMLInputElement | HTMLTextAreaElement) {
    this.showTooltip = true;
    inputElement.select();
    this._document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }
}

export const MockModalInviteComponent = MockComponent({
  selector: 'kui-modal-invite',
  inputs: ['invite', 'challengeId', 'challengeInviteMessage', 'challengeTitle'],
});
