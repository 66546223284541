<ng-container *transloco="let t">
  <kui-email-screens-base [headerTitle]="headerTitle()">
    <div icon class="header-icon">
      <svg-icon src="/assets/icons/checkmark-empty-circle.svg"></svg-icon>
    </div>
    <div instructions class="instructions">
      <kui-user-email [email]="email()"></kui-user-email>
      <p class="instruction" [innerHTML]="t('ui.Check your email and enter the 6-digit code you receive below.')"></p>
      <p class="instruction">
        {{ customInstructionText() }}
      </p>
    </div>
    <ng-container content>
      <form class="email-code-form" [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
        <div class="sub-container">
          <label>
            <span class="form-label">
              {{ t('ui.Code') }}
              <a class="code-show-help" [routerLink]="noCodeLink()" [state]="{ email: email() }">
                {{ t('ui.Didnt receive a code?') }}
              </a>
            </span>
            <input
              class="code-input"
              type="tel"
              inputmode="number"
              maxlength="6"
              required
              formControlName="code"
              name="code"
              id="code"
              data-test="codeInput"
              (keydown)="handleKeydown($event)"
              (change)="codeChanged()"
              (input)="codeChanged()" />
          </label>
          @if (formatError) {
            <p class="error">
              <svg-icon src="/assets/icons/alert-circle.svg"></svg-icon>
              <span>{{ t('ui.The code you entered should be 6 digits.') }}</span>
            </p>
          }
          @if (verifyErrorMessage() !== '') {
            <p class="error">
              <svg-icon src="/assets/icons/alert-circle.svg"></svg-icon>
              {{ verifyErrorMessage() }}
            </p>
          }
        </div>
        <button
          class="button button-pill button-submit"
          type="submit"
          [disabled]="!form?.valid || verifyErrorMessage() !== '' || verifyDisabled()">
          {{ t('ui.Verify') }}
        </button>
      </form>
    </ng-container>
    <ng-container footer>
      @if (verifyType() === VerifyType.register) {
        <p>{{ t('ui.Already have a Kumanu account?') }}</p>
        <a [routerLink]="haveAccountLink()" class="button-text">{{ t('ui.Sign in') }}</a>
      }
    </ng-container>
  </kui-email-screens-base>
</ng-container>
