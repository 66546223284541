import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { TranslocoDirective, provideTranslocoScope } from '@jsverse/transloco';
import { EnvironmentVariablesService } from '@kenv';
import { productDisplayName } from '@kservice';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-version-tag',
  standalone: true,
  templateUrl: './version-tag.component.html',
  styleUrls: ['./version-tag.component.scss'],
  imports: [CommonModule, TranslocoDirective],
  providers: [provideTranslocoScope('ui')],
})
export class VersionTagComponent {
  constructor(private _environmentVariablesService: EnvironmentVariablesService) {
    this.appVersion = this._environmentVariablesService.versions.app[this._environmentVariablesService.product];
    this.npmVersion = this._environmentVariablesService.versions.npm_version;
    this.revision = this._environmentVariablesService.versions.revision;
    this.version = this._environmentVariablesService.latestVersion;
  }

  @Input()
  @HostBinding('class.hidden')
  hidden = false;

  appVersion: string;
  npmVersion: string;
  product = productDisplayName;
  revision: string;
  version: string;
}

export const MockVersionTagComponent = MockComponent({ selector: 'kui-version-tag', inputs: ['hidden'] });
