import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { TranslocoLoaderData } from '@jsverse/transloco/lib/transloco.loader';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(scopedLang: string, data?: TranslocoLoaderData) {
    const { scope } = data ?? {};
    // NOTE: when a scope is provided, scopedLang will be `scope/lang`
    const lang = scope ? scopedLang?.replace(`${scope}/`, '') : scopedLang;
    const normalizedLang = lang?.length > 6 ? lang?.substring(0, 6) : lang;
    const base = `/assets/i18n/${normalizedLang}.json`;
    if (!scope) {
      return this.http.get<Translation>(base);
    }
    return forkJoin([
      this.http.get<Translation>(base),
      this.http.get<Translation>(`/assets/i18n/${scope}/${normalizedLang}.json`),
    ]).pipe(
      map(([baseTranslation, libTranslation]) => {
        return { ...baseTranslation, ...libTranslation };
      })
    );
  }
}
