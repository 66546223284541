<ng-container *transloco="let t">
  <div class="accordion" aria-label="Accordion">
    <label class="caret" for="{{ key }}">
      <ng-content select="[acc-open]"></ng-content>
      <!--
        t(ui.Click to close accordion)
        t(ui.Click to open accordion)
      -->
      <svg-icon
        src="/assets/icons/chevron-left-nocircle.svg"
        class="caret-arrow"
        [ngClass]="{ 'is-animating': isAnimating }"
        [attr.aria-label]="t('ui.Click to ' + (isOpen ? 'close' : 'open') + ' accordion')"
        (click)="accordionClick(input.checked)"></svg-icon>
    </label>
    <!--
      t(ui.Accordion is open)
      t(ui.Accordion is closed)
    -->
    <input
      type="checkbox"
      #input
      (click)="accordionClick(input.checked)"
      id="{{ key }}"
      [checked]="isOpen"
      [attr.aria-label]="t('ui.Accordion is ' + (isOpen ? 'open' : 'closed'))" />
    <div class="body" #collapsibleBody>
      <ng-content select="[acc-collapsible]"></ng-content>
    </div>
  </div>
</ng-container>
