/* eslint-disable */
// NOTE :: DO NOT EDIT :: this file is automatically generated by generate-environment-variables.js node script

export const envVariables: { [key: string]: string } = {
  CIRCLE_TAG: 'prod-insightful-v44',
  PROJECT: 'insightful',
};
export const latestTag: string = 'prod-insightful-v44';
export const latestVersion: string = 'v44';
export const versions: { app: { [key: string]: string }, branch: string, npm_version: string, revision: string } = {
  app: {
    purposeful: '6.6.0',
    insightful: '3.0.0',
  },
  branch: 'HEAD',
  npm_version: '164.0.0',
  revision: '775bf38f1',
};
/* eslint-enable */
