import { TranslocoFallbackStrategy } from '@jsverse/transloco';

export class CustomFallbackStrategy implements TranslocoFallbackStrategy {
  getNextLangs(failedLang: string): string[] {
    if (!failedLang || typeof failedLang !== 'string') {
      return ['en'];
    }

    // NOTE: when a scope is provided, scopedLang will be `scope/lang`
    const [, lang] = failedLang?.includes('/') ? failedLang.split('/') : [null, failedLang];
    const normalizedLang = lang?.length > 6 ? lang?.substring(0, 6) : lang;
    return [normalizedLang ?? 'en'];
  }
}
