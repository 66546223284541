import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoDirective, provideTranslocoScope } from '@jsverse/transloco';
import { WINDOW } from '@kservice';
import { User } from '@ktypes/models';

@Component({
  selector: 'kauth-code',
  standalone: true,
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
  imports: [TranslocoDirective],
  providers: [provideTranslocoScope('auth')],
})
export class CodeComponent implements OnInit {
  token: string;
  refreshToken: string;
  error: string;
  authorizingUser: boolean;

  constructor(
    private _activatedRoute: ActivatedRoute,
    @Inject(WINDOW) private _window: Window
  ) {}

  ngOnInit() {
    this.authorizingUser = true;
    this.token = this._activatedRoute?.snapshot?.queryParams?.['token'] as string;
    this.refreshToken = this._activatedRoute?.snapshot?.queryParams?.['refresh'] as string;
    this.error = this._activatedRoute?.snapshot?.queryParams?.['error'] as string;

    // only show "Authorizing message" for 8s max
    setTimeout(() => {
      this.authorizingUser = false;
    }, 8000);

    if (this.token != null && this.token !== '' && this.refreshToken != null && this.refreshToken !== '') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (this._window.opener && typeof (this._window.opener as any).kpUpdateUser === 'function') {
        void this.updateUserAndClose();
      }
    }
  }

  private async updateUserAndClose() {
    // No type available for window.opener
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    const updatedUser = (await this._window.opener
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .kpUpdateUser({ token: this.token, refreshToken: this.refreshToken })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .catch((error: string) => {
        console.warn(error);
        setTimeout(this.close.bind(this), 8000);
      })) as User;
    if (updatedUser) {
      this.authorizingUser = false;
      this.close();
    } else {
      // If there was a problem updating the user, but it didn't throw an error, close window after 16s
      console.warn({ error: 'SSO_LOGIN_ERROR', message: 'problem updating the user but an error was not thrown' });
      setTimeout(this.close.bind(this), 16000);
    }
  }

  close() {
    this._window?.close();
  }
}
