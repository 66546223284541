import { Injectable } from '@angular/core';
import { InviteEmail } from '@ktypes/models';

@Injectable({
  providedIn: 'root',
})
export class SharedConstants {
  static actionChoice = {
    clickToCreateAccount: 'click to create account',
    clickNotNow: 'click Not Now',
    clickX: 'click x',
    clickOutsideModal: 'click outside modal',
    clickBannerToCreateAccount: 'click banner to create account',
  };

  static readonly platform = 'Web';

  static readonly BATCH_THROTTLE_TIME = 350;

  static inviteEmails: InviteEmail = {
    coworker: {
      subject: 'Get started with Purposeful!',
      body: 'Through our organization, we have access to a wellbeing app called Purposeful — which helps you make progress in areas you’re trying to improve, and build positive habits. It’s free for us to use, so give it a try! Get started by following the link below.',
    },
    friend: {
      subject: 'Get the Purposeful app free with my link!',
      body: 'Through my organization, I have access to a wellbeing app called Purposeful — it supports you in making progress in areas you’re trying to improve, and also helps with building positive habits. Because I have access, I can share it with you for free! Follow the link below to check it out.',
    },
    spouse: {
      subject: 'Join me in Purposeful!',
      body: 'Through my organization, I was given access to a wellbeing app called Purposeful — it helps me build positive habits, and make progress in areas where I’m trying to improve. With my link you can also get free access. Follow the link below to get started.',
    },
  };

  static DEFAULT_LANGUAGE = 'en';
  static supportedLanguages: { english: string; language: string; name: string }[] = [
    {
      english: 'Arabic',
      language: 'ar',
      name: 'عربي',
    },
    {
      english: 'Chinese (Simplified)',
      language: 'zh-CN',
      name: '简体中文',
    },
    {
      english: 'German',
      language: 'de',
      name: 'Deutsch',
    },
    {
      english: 'English',
      language: 'en',
      name: 'English',
    },
    {
      english: 'Spanish',
      language: 'es',
      name: 'Español',
    },
    {
      english: 'French',
      language: 'fr',
      name: 'Français',
    },
    {
      english: 'Hindi',
      language: 'hi',
      name: 'नहीं',
    },
    {
      english: 'Italian',
      language: 'it',
      name: 'Italiano',
    },
    {
      english: 'Japanese',
      language: 'ja',
      name: '日本語',
    },
    {
      english: 'Portuguese',
      language: 'pt',
      name: 'Português',
    },
  ];
}
