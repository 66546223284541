<ng-container *transloco="let t">
  <div class="site-map-container">
    <h1 class="site-map-title">{{ t('ui.Site Map') }}</h1>
    <p class="site-map-instructions">
      {{ t('ui.Select a link from the options below to navigate directly to the corresponding page in the app.') }}
    </p>
    <div class="section-wrap">
      @if (mainCategory$ | async; as mainCategory) {
        <section class="section-main">
          <h2 class="section-heading">{{ t('ui.Main productDisplayName Sections', { productDisplayName }) }}</h2>
          <ul>
            @for (navItem of mainCategory; track navItem) {
              <li>
                <h3 class="page-main">
                  @if (navItem.icon) {
                    <svg-icon class="map-link-icon" [src]="navItem.icon"></svg-icon>
                  }
                  @if (navItem.link) {
                    <a class="map-link" [routerLink]="navItem.link" [queryParams]="navItem.queryParams">
                      {{ t(navItem.mapTitle || navItem.title) }}
                    </a>
                  } @else {
                    <span class="map-link-text">
                      {{ t(navItem.mapTitle || navItem.title) }}
                    </span>
                  }
                </h3>
                @if (navItem.children?.length > 0) {
                  <ul>
                    @for (navChild of navItem.children; track navChild) {
                      <li>
                        @if (navChild.link) {
                          <a class="map-link" [routerLink]="navChild.link" [queryParams]="navChild.queryParams">
                            {{ t(navChild.mapTitle || navChild.title) }}
                          </a>
                        } @else {
                          <span class="map-link-text">
                            {{ t(navChild.mapTitle || navChild.title) }}
                          </span>
                        }
                      </li>
                    }
                  </ul>
                }
              </li>
            }
          </ul>
        </section>
      }
      @if (
        {
          quickActions: (quickActions$ | async),
          features: (features$ | async),
          support: (support$ | async)
        };
        as secondary
      ) {
        @if (secondary.quickActions?.length > 0 || secondary.features?.length > 0 || secondary.support?.length > 0) {
          <section class="section-secondary">
            <h2 class="section-heading">{{ t('ui.Additional Sections') }}</h2>
            <ul>
              @if (secondary.quickActions?.[0]; as navItem) {
                <li>
                  <h3 class="page-main">
                    @if (navItem.link) {
                      <a class="map-link" [routerLink]="navItem.link" [queryParams]="navItem.queryParams">
                        {{ t(navItem.mapTitle || navItem.title) }}
                      </a>
                    } @else {
                      <span class="map-link-text">
                        {{ t(navItem.mapTitle || navItem.title) }}
                      </span>
                    }
                  </h3>
                  @if (navItem.children?.length > 0) {
                    <ul>
                      @for (navChild of navItem.children; track navChild) {
                        <li>
                          @if (navChild.link) {
                            <a class="map-link" [routerLink]="navChild.link" [queryParams]="navChild.queryParams">
                              {{ t(navChild.mapTitle || navChild.title) }}
                            </a>
                          } @else {
                            <span class="map-link-text">
                              {{ t(navChild.mapTitle || navChild.title) }}
                            </span>
                          }
                        </li>
                      }
                    </ul>
                  }
                </li>
              }
              @if (secondary.features?.length > 0) {
                <li>
                  <h3 class="page-main">{{ t('ui.Features') }}</h3>
                  <ul>
                    @for (navItem of secondary.features; track navItem) {
                      <li>
                        @if (navItem.link) {
                          <a class="map-link" [routerLink]="navItem.link" [queryParams]="navItem.queryParams">
                            {{ t(navItem.mapTitle || navItem.title) }}
                          </a>
                        } @else {
                          <span class="map-link-text">
                            {{ t(navItem.mapTitle || navItem.title) }}
                          </span>
                        }
                      </li>
                    }
                  </ul>
                </li>
              }
              @if (secondary.support?.length > 0) {
                <li>
                  <h3 class="page-main">{{ t('ui.Support') }}</h3>
                  <ul>
                    @for (navItem of secondary.support; track navItem) {
                      <li>
                        @if (navItem.link) {
                          <a class="map-link" [routerLink]="navItem.link" [queryParams]="navItem.queryParams">
                            {{ t(navItem.mapTitle || navItem.title) }}
                          </a>
                        } @else {
                          <span class="map-link-text">
                            {{ t(navItem.mapTitle || navItem.title) }}
                          </span>
                        }
                      </li>
                    }
                  </ul>
                </li>
              }
            </ul>
          </section>
        }
      }
    </div>
  </div>
</ng-container>
