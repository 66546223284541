import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent {
  @Input() info: string; // NOTE: Translate before passing in
  @Input() title: string; // NOTE: Translate before passing in

  @Output() closeClicked = new EventEmitter<boolean>();
}

export const MockModalInfoComponent = MockComponent({
  selector: 'kui-modal-info',
  inputs: ['info', 'title'],
});
