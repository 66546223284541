<ng-container *transloco="let t">
  @if (error) {
    <div class="error-generic">
      <h2 class="error-header">{{ t('auth.Login Error') }}</h2>
      <p>
        {{
          t(
            'auth.There was an error trying to login with the selected Google account. If the error persists, please contact'
          )
        }}
        <a href="mailto:support@kumanu.com" class="error-email-link" target="_blank">support&#64;kumanu.com</a>
        {{ t('auth.for assistance.') }}
      </p>
    </div>
  }
  @if (!error) {
    <div class="error-generic">
      @if (authorizingUser) {
        <h2 class="error-header">{{ t('auth.Logging In') }}</h2>
      }
      @if (!authorizingUser) {
        <h2 class="error-header">{{ t('auth.Login Error') }}</h2>
      }
      @if (authorizingUser) {
        <p>{{ t('auth.Finalizing user authentication') }}&hellip;</p>
      }
      @if (!authorizingUser) {
        <p>
          {{
            t('auth.There was an error trying to finalize user authentication. If the error persists, please contact')
          }}
          <a href="mailto:support@kumanu.com" class="error-email-link" target="_blank">support&#64;kumanu.com</a>
          {{ t('auth.for assistance.') }}
        </p>
      }
    </div>
  }
  <button class="button button-pill error-button" (click)="close()">{{ t('auth.Close Window') }}</button>
</ng-container>
