import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TRANSLOCO_FALLBACK_STRATEGY, TranslocoDirective, provideTransloco } from '@jsverse/transloco';
import { PAGE_MAPPING } from '@kanalytics';
import { NAV_ITEM_LIST } from '@kbloc';
import { CONFIG } from '@kenv';
import { CustomFallbackStrategy, TranslocoHttpLoader } from '@kf-loc';
import { environment } from '@ki/../environments/environment';
import { AppRoutingModule } from '@ki/app-routing.module';
import { AppComponent } from '@ki/app.component';
import { CoreModule } from '@ki/core/core.module';
import { pageMappingData } from '@ki/core/page-mapping-info';
import { httpInterceptorProviders } from '@ki/http-interceptors';
import { SentryErrorHandler } from '@ki/sentry/sentry.service';
import { Constants } from '@ki/shared/constants.service';
import { SharedModule } from '@ki/shared/shared.module';
import { WINDOW_PROVIDERS } from '@kservice';
import { TooltipVariations } from '@ktypes/props';
import { VersionTagComponent } from '@kui';
import { TIPPY_CONFIG, popperVariation, tooltipVariation } from '@ngneat/helipopper';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CoreModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    TranslocoDirective,
    VersionTagComponent,
  ],
  providers: [
    WINDOW_PROVIDERS,
    Title,
    httpInterceptorProviders,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: TRANSLOCO_FALLBACK_STRATEGY,
      useClass: CustomFallbackStrategy,
    },
    provideTransloco({
      config: {
        // NOTE: Update this whenever languages/translations are added
        availableLangs: [
          {
            id: 'ar',
            label: 'عربي',
          },
          {
            id: 'zh-CN',
            label: '简体中文）',
          },
          {
            id: 'de',
            label: 'Deutsch',
          },
          {
            id: 'en',
            label: 'English',
          },
          {
            id: 'es',
            label: 'Español',
          },
          {
            id: 'fr',
            label: 'Français',
          },
          {
            id: 'hi',
            label: 'नहीं',
          },
          {
            id: 'it',
            label: 'Italiano',
          },
          {
            id: 'ja',
            label: '日本語',
          },
          {
            id: 'pt',
            label: 'Português',
          },
        ],
        defaultLang: 'en',
        fallbackLang: 'en',
        missingHandler: {
          useFallbackTranslation: true,
        },
        reRenderOnLangChange: true,
        prodMode: ['prod', 'staging'].includes(environment.environment),
      },
      loader: TranslocoHttpLoader,
    }),
    { provide: CONFIG, useValue: environment },
    { provide: NAV_ITEM_LIST, useValue: Constants.navItemList },
    { provide: PAGE_MAPPING, useValue: pageMappingData },
    {
      provide: TIPPY_CONFIG,
      useValue: {
        defaultVariation: 'insightful',
        variations: {
          tooltip: tooltipVariation,
          popper: popperVariation,
          insightful: TooltipVariations.insightful,
          insightfulInfo: TooltipVariations.insightfulInfo,
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
