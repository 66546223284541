import { clearEmptyProps } from '@kutil';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import { LOGIN_ERRORS, LoginError, UserType } from '../enums';
import { SOCIAL_SSO, SSO_PROVIDERS } from './auth-provider-state.model';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { LiveSupport } from './live-support.model';
import { Group } from './og.model';
import { Serializable } from './serializable.model';
import { Settings } from './settings.model';
import { SubscriptionPlan } from './subscription-plan.model';
import { UserFeature } from './user-feature.model';
import { UserRole } from './user-role.model';

export class User implements Deserializable<JsonObject, User>, Serializable<JsonObject> {
  type: UserType;

  constructor(
    public id?: string,
    public email?: string,
    public firstName?: string,
    public lastName?: string,
    public nickname?: string,
    public features?: UserFeature[],
    public isSsoUser?: boolean,
    public provider?: string,
    private _type?: string,
    public error?: string,
    public group?: Group,
    public groupId?: string,
    public settings?: Settings,
    public subscriptionPlan?: SubscriptionPlan,
    public subscriptionPlanEmail?: string,
    public proposedEmail?: string,
    public roles?: UserRole[],
    public unconfirmedEmail?: string,
    public dateDeviceCreated?: Date,
    public userMfaEnabledSetting?: boolean
  ) {
    this.type = UserType[_type as keyof typeof UserType];
  }

  deserialize(input: JsonObject): User {
    if (input == null) {
      return null;
    }

    this.id = input['id'] as string;
    this.email = input['email'] as string;
    this.firstName = input['firstName'] as string;
    this.lastName = input['lastName'] as string;
    this.nickname = input['nickname'] as string;
    this.features = (input['features'] as UserFeature[])?.map?.((feature) => new UserFeature().deserialize(feature));
    if (input['provider']) {
      this.isSsoUser = determineIfSsoUser(input['provider']);
      this.provider = input['provider'] as string;
    }
    this.type = input['type'] as UserType;
    this.error = input['error'] as string;
    this.group = new Group().deserialize(input['group']);
    this.groupId = input['groupId'] as string;
    this.settings = new Settings().deserialize(input['settings']);
    this.subscriptionPlan = new SubscriptionPlan().deserialize(input['subscriptionPlan']);
    this.subscriptionPlanEmail = input['subscriptionPlanEmail'] as string;
    this.proposedEmail = input['proposedEmail'] as string;
    this.roles = (input['roles'] as UserRole[])?.map?.((role) => new UserRole().deserialize(role));
    this.unconfirmedEmail = input['unconfirmedEmail'] as string;
    this.dateDeviceCreated = input['dateDeviceCreated'] ? new Date(input['dateDeviceCreated']) : undefined;
    this.userMfaEnabledSetting = !!input['userMfaEnabledSetting'];

    return this;
  }

  serialize(forStorage = false): JsonObject {
    if (forStorage) {
      return pick(this, ['id', 'email', 'groupId', 'nickname', 'type', 'features', 'roles', 'settings.language']);
    }
    return cloneDeep(this);
  }

  combine(updatedUser: User): User {
    if (!updatedUser) {
      return this;
    }

    const cleanedUser: Partial<User> = clearEmptyProps(this) as Partial<User>;
    const cleanedUpdatedUser: Partial<User> = clearEmptyProps(updatedUser) as Partial<User>;
    const allFeatures = [...(cleanedUser.features || []), ...(cleanedUpdatedUser.features || [])];
    const combinedUniqueFeatures = [
      ...new Map(allFeatures.map((feature) => [feature['key'], feature])).values(),
    ].filter((feature) => Object.values(feature).some((featureValues) => !!featureValues));
    const features = combinedUniqueFeatures.length > 0 ? combinedUniqueFeatures : undefined;
    let group = cleanedUser.group;
    if (group || cleanedUpdatedUser.group) {
      const liveSupport = merge(
        clearEmptyProps(group?.liveSupport),
        clearEmptyProps(cleanedUpdatedUser.group?.liveSupport)
      ) as LiveSupport;
      group = new Group().deserialize({ ...group, ...cleanedUpdatedUser.group, liveSupport });
    }
    return new User().deserialize({ ...cleanedUser, ...cleanedUpdatedUser, ...{ features, group } });
  }

  get isLoggedIn(): boolean {
    // TODO: Re-evaluate when there is a better way to determine logged in status
    return this.type === UserType.user && this.email != null && !LOGIN_ERRORS.includes(this.error as LoginError);
  }

  get hasLoadedData(): boolean {
    return !!(this.id && this.dateDeviceCreated && Array.isArray(this.features)) || !!this.error;
  }

  get hasNoData(): boolean {
    return !(this.id && this.dateDeviceCreated && Array.isArray(this.features));
  }

  get providerLabel(): string {
    return this.provider === SOCIAL_SSO.SignInWithApple ? 'Apple' : this.provider;
  }
}

function determineIfSsoUser(provider: string) {
  return SSO_PROVIDERS.includes(provider);
}
